import styled from "styled-components"
import {
  Badge,
  Button,
  Div,
  DropdownArrowIcon,
  Flex,
  fontFamilies
} from "@ikiru/talentis-fpc"
import { backgroundOffLimits } from "components/OffLimits/style"

export const PersonWrapper = styled(Flex)`
  flex-direction: column;
  border: 2px solid #d5dee7;
  border-radius: 10px;
  margin-bottom: 10px;
`

export const PersonalInfoWrapper = styled(Flex)`
  justify-content: space-between;
  border-bottom: 1px solid #d5dee7;
  flex-wrap: wrap;
  width: 100%;
`

export const FlexItemStyled = styled(Flex)<{ isOfflimits?: boolean }>`
  border-right: 1px solid #d5dee7;

  &:nth-child(1) {
    width: 30%;
    ${backgroundOffLimits}
  }
  &:nth-child(2) {
    width: 25%;
  }
  &:nth-child(3) {
    width: 30%;
  }
  &:nth-child(4) {
    border-right: none;
    width: 15%;
  }

  @media (max-width: 768px) {
    &:nth-child(1) {
      ${backgroundOffLimits}
      border-right: 1px solid #d5dee7;
      width: 50%;
    }
    &:nth-child(2) {
      width: 50%;
    }
    &:nth-child(3) {
      border-right: 1px solid #d5dee7;
      width: 50%;
    }
    &:nth-child(4) {
      border-right: none;
      width: 50%;
    }
    border-right: none;
    border-bottom: 1px solid #d5dee7;
  }
`

type StyledBadgeProps = {
  count: number
}

export const StyledBadge = styled(Badge)<StyledBadgeProps>`
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme, count }) =>
    count === 0 ? theme.colors.white.standard : theme.colors.orange.darkest};
  background: ${({ theme, count }) =>
    count === 0 ? theme.colors.grey.standard : theme.colors.red.lightest};
  height: 16px;
  border-radius: 15%;
  margin-left: 5px;
  padding: 3px;
  font-family: ${fontFamilies.sourceSans};
`

export const StyleDiv = styled(Div)`
  margin-top: -4px;
  background-color: #d5dee7;
  color: #224160;
  padding: 5px;
  margin-bottom: 4px;
`

export const PersonalNotesWrapper = styled(Flex)`
  margin-top: 5px;
  flex-wrap: wrap;
  width: 100%;
`

export const PersonalNoteItemStyled = styled(Flex)`
  &:nth-child(1) {
    width: 40%;
  }
  &:nth-child(2) {
    width: 40%;
  }

  @media (max-width: 768px) {
    &:nth-child(1) {
      width: 100%;
    }
    &:nth-child(2) {
      margin-left: 5px;
      margin-top: 10px;
      width: 1000%;
    }
  }
`
