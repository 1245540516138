import React, { useCallback } from "react"
import { Flex, H2, Loader } from "@ikiru/talentis-fpc"
import { PersonWrapper, StyleDiv } from "./styles"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import {
  getLocalContactLookupResult,
  getLocalSearchResult
} from "views/internal-search/actions"
import { Pagination } from "components/functional/pagination/Pagination"
import { InternalPersonType } from "views/internal-search/InternalSearchModule/types"
import PersonRecord from "./PersonRecord"

const InternalSearchResults = () => {
  const {
    isLoadingSearchResults,
    internalPersons,
    internalSearchPage,
    setInternalPersons,
    setInternalSearchPage,
    filters,
    isLookup,
    lookupSearchString
  } = useInternalSearch()

  const handlePageChange = useCallback(
    async (page: number) => {
      setInternalSearchPage({ ...internalSearchPage, pageNumber: page })
      let searchResult = isLookup
        ? await getLocalContactLookupResult(lookupSearchString, page)
        : await getLocalSearchResult(filters, page)

      setInternalSearchPage({
        hasNextPage: searchResult.hasNextPage,
        hasPreviousPage: searchResult.hasPreviousPage,
        pageCount: searchResult.pageCount,
        pageNumber: searchResult.pageNumber,
        pageSize: searchResult.pageSize,
        totalItemCount: searchResult.totalItemCount,
        maxPages: 9
      })
      setInternalPersons(searchResult.people)
    },

    [
      filters,
      internalSearchPage,
      lookupSearchString,
      isLookup,
      setInternalPersons,
      setInternalSearchPage
    ]
  )

  return (
    <>
      {isLoadingSearchResults ? (
        <Loader />
      ) : (
        <>
          <StyleDiv>
            <H2 m="0" color="#224160">
              {internalSearchPage.totalItemCount} results from the TalentGraph
            </H2>
          </StyleDiv>

          {internalPersons?.map((person: InternalPersonType, index: number) => (
            <PersonWrapper key={index}>
              <PersonRecord person={person} />
            </PersonWrapper>
          ))}
          <Flex width="100%" flexDirection="column">
            {internalSearchPage.pageCount > 1 && (
              <Flex width="100%" justifyContent="center" mb={20}>
                <Pagination
                  {...internalSearchPage}
                  onPageChange={handlePageChange}
                />
              </Flex>
            )}
          </Flex>
        </>
      )}
    </>
  )
}

export default InternalSearchResults
