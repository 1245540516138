import React, { useState, useRef, useEffect } from "react"
import { Field } from "components/visual/field/Field"
import { Input, InputProps } from "@ikiru/talentis-fpc"
import { Error } from "@ikiru/talentis-fpc"
import { useField } from "formik"
import { useIntl } from "react-intl"
import DatePicker from "react-datepicker"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import { Nullable } from "tsdef"
import {
  DatepickerHeader,
  DatepickerCalendarIcon as CalendarIcon,
  DatepickerHeaderProps,
  StyledDatepickerContainer
} from "@ikiru/talentis-fpc"
import { useStatus } from "components/functional/formik/hooks/use-status"
import { getDatePatternMask, getLocaleDatePattern } from "utils/format-date"

type FormikDatepickerProps = InputProps & {
  label?: React.ReactChild
  name: Pick<InputProps, "name">
  maxDate?: Date
  minDate?: Date
  dateFormat?: string
  onChangeDate?: (date: Nullable<Date>) => void
}

type DatapickerRenderHeaderProps = {
  date: Date
} & Pick<
  DatepickerHeaderProps,
  "increaseMonth" | "decreaseMonth" | "changeYear"
>

export const FormikDatepicker = React.forwardRef(
  (props: FormikDatepickerProps, ref) => {
    const {
      label,
      name,
      onBlur,
      maxDate,
      minDate,
      dateFormat = getLocaleDatePattern(),
      id,
      onChangeDate,
      defaultValue,
      ...explicitProps
    } = props
    const intl = useIntl()
    const [formikProps, meta, helpers] = useField(name as string)

    const [date, setDate] = useState<Nullable<Date>>()

    useEffect(() => {
      const dateInitialValue = formikProps.value
        ? moment.utc(formikProps.value, dateFormat).toDate()
        : null
      setDate(dateInitialValue)
    }, [formikProps.value, dateFormat])

    // "any" because this is problem with DataPicker types doesn't include input prop
    const datepickerRef = useRef<any>(ref)
    const status = useStatus(meta)

    const onChange = (date: Nullable<Date>) => {
      onChangeDate && onChangeDate(date)
      if (moment(date).isValid()) {
        setDate(date)
        helpers.setTouched(true)
        helpers.setValue(date)
      } else {
        helpers.setTouched(true)
        helpers.setValue(null)
        setDate(null)
      }
    }

    useEffect(() => {
      if (defaultValue) {
        const dateInitialValue = defaultValue
          ? moment.utc(defaultValue, dateFormat).toDate()
          : null

        setDate(dateInitialValue)
      }
    }, [defaultValue])

    const renderCustomHeader = ({
      date,
      decreaseMonth,
      increaseMonth,
      changeYear
    }: DatapickerRenderHeaderProps) => {
      const month = moment.months()[moment(date).month()]
      const year = moment(date).year()

      return (
        <DatepickerHeader
          month={month}
          year={year}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          changeYear={changeYear}
        />
      )
    }

    return (
      <Field>
        <StyledDatepickerContainer>
          <DatePicker
            ref={datepickerRef}
            id={id}
            name={name}
            calendarClassName="talentis-datepicker"
            dateFormat={dateFormat}
            showPopperArrow={false}
            selected={date}
            minDate={minDate}
            maxDate={maxDate}
            locale={intl.locale}
            onChange={onChange}
            popperPlacement="bottom-end"
            popperClassName="talentis-datepicker-popper"
            renderCustomHeader={renderCustomHeader}
            onCalendarClose={() => {
              datepickerRef.current?.input.inputElement.blur()
            }}
            customInput={
              <Input
                ref={ref}
                onInput={(e) => {
                  // @ts-ignore
                  if (e.target.value === "_/__/____" || !e.target.value) {
                    helpers.setTouched(true)
                    helpers.setValue(null)
                    setDate(null)
                  }
                }}
                {...formikProps}
                {...explicitProps}
                label={label}
                data-e2e-target="form-datapicker-input"
                onBlur={(event) => {
                  formikProps.onBlur(event)
                  onBlur && onBlur(event)
                }}
                sufix={() => {
                  return <CalendarIcon />
                }}
                mask={getDatePatternMask(dateFormat)}
                status={status}
              />
            }
          />
        </StyledDatepickerContainer>
        {meta.error && meta.touched && <Error>{meta.error}</Error>}
      </Field>
    )
  }
)
