import React, { useMemo, useCallback } from "react"
import { Div, Flex, Note, Button, PencilIcon } from "@ikiru/talentis-fpc"
import { formatDateTime } from "utils/format-date"
import { messages } from "setup/messages/messages"
import { RouterUrl } from "setup/router/routes"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import AttachmentModel from "components/PersonRecords/Notes/NoteModal/AttachmentModel"
import { generateNoteDate } from "components/NoteItem/helpers"
import { PersonNote } from "views/persons/components/person-notes/types"
import { CampaignNote } from "views/campaigns/components/campaign-notes/types"

export type NoteType = PersonNote | CampaignNote

type NoteItemProps = {
  note: NoteType
  containerDataE2ETarget?: string
  buttonEditDataE2ETarget?: string
  openModal: (note: NoteType) => void
}

const NoteCampaignItem = ({
  note,
  containerDataE2ETarget,
  buttonEditDataE2ETarget,
  openModal
}: NoteItemProps) => {
  const {
    noteTitle,
    noteDescription,
    createdBy,
    createdDate,
    updatedBy,
    updatedDate,
    linkAssignment,
    linkCampaign
  } = generateNoteDate(note)

  const { open } = useModal()

  const projectRoute = useMemo(
    () =>
      linkAssignment?.id
        ? `${RouterUrl.AssignmentDetails}/${linkAssignment?.id}`
        : linkCampaign?.id
        ? `${RouterUrl.CampaignDetails}/${linkCampaign?.id}`
        : " ",
    [linkCampaign, linkAssignment]
  )

  const seeDocument = useCallback(
    (documentId: string) => {
      open(
        ModalNames.EmailAttachments,
        <AttachmentModel noteId={note.id} documentId={documentId} />
      )
    },
    [note.id, open]
  )

  return (
    <Flex
      as="li"
      bg="grey.lightest"
      flexDirection="column"
      pb={0}
      pt="xxs"
      m={0}
      data-e2e-target={containerDataE2ETarget}
    >
      <Flex width="100%">
        <Note
          title={noteTitle}
          description={noteDescription}
          createdDate={createdDate}
          createdBy={createdBy}
          formattedCreatedDate={formatDateTime(createdDate)}
          formattedUpdatedDate={
            updatedDate ? formatDateTime(updatedDate) : null
          }
          tag={linkAssignment?.name || linkCampaign?.name}
          tagVariant={linkCampaign?.name ? "grey-standard" : undefined}
          updatedBy={updatedBy}
          updatedDate={updatedDate}
          moreButtonLabel={messages.person.note.showFullNote}
          lessButtonLabel={messages.person.note.showLessNote}
          projectUrl={projectRoute}
          documents={note.documents}
          onDocumentClick={seeDocument}
          openModal={() => null}
        />
        <Div>
          <Button
            data-e2e-target={buttonEditDataE2ETarget}
            mode="standard-white"
            size="action-small"
          >
            <PencilIcon
              width={12}
              height={12}
              onClick={() => openModal(note)}
            />
          </Button>
        </Div>
      </Flex>
    </Flex>
  )
}

export default React.memo(NoteCampaignItem)
