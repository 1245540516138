import React from "react"
import { messages } from "setup/messages/messages"
import { Helmet } from "react-helmet"
import { InternalSearchModule } from "./InternalSearchModule"
import { InternalSearchPage } from "."

export const InternalSearch = () => {
  return (
    <>
      <Helmet>
        <title>{messages.seo.title.format(messages.seo.internalSearch)}</title>
      </Helmet>
      <InternalSearchModule>
        <InternalSearchPage />
      </InternalSearchModule>
    </>
  )
}
