import React, { useCallback, useEffect, useState } from "react"
import { Button, Div, Flex } from "@ikiru/talentis-fpc"
import { Loader } from "components/Loader/Loader"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { AssignmentData } from "views/assignments/components/assignment-data"
import { Assignment } from "views/assignments/assignment.types"
import { e2eTargets } from "views/assignments/constants/definitions"
import { CandidatesList } from "views/assignments/components/candidates-list"
import { Helmet } from "react-helmet"
import { messages } from "setup/messages/messages"
import AssignmentNotes from "views/assignments/components/assignment-notes"
import { AssignmentTags } from "views/assignments/components/assignment-tags"
import { useTelemetry } from "utils/hooks/use-telemetry"
import AssignmentPersonOverlay from "views/persons/components/person-overlay/AssignmentPersonOverlay"
import { scrollToTop } from "views/search/SearchModule/utils"
import { getAppliedFiltersParams } from "views/assignments/components/candidates-list/components/candidate-filters/helper"
import { Tabs } from "views/companies/components/company-details/components/Tabs/Tabs"
import Tab from "views/companies/components/company-details/components/Tabs/Tab"
import { LinkContainerStyled, TGFlex } from "./style"
import ClientPortalConfigurationWrapper from "../../client-portal-configuration/ClientPortalConfigurationWrapper"
import { useAssignmentNotes } from "views/assignments/components/assignment-notes/hooks"
import AssignmentTemplates from "views/assignments/components/assignment-ai-templates"
import useAssignmentTemplates from "views/assignments/components/assignment-ai-templates/hooks/useAssignmentTemplates"
import AssignmentDetailSection from "views/assignments/components/assignment-details-section/AssignmentDetailsSection"
import { AssignmentTargetCompanies } from "views/assignments/components/assignment-target-companies"
import AssignmentCompanyPersonOverlay from "views/persons/components/person-overlay/AssignmentCompanyPersonOverlay"

export const AssignmentDetails = () => {
  const {
    assignmentDetails,
    setAssignmentDetails,
    assignmentId,
    selectedCandidatedId,
    onPageChange,
    getAssignmentCandidates,
    selectedStage,
    appliedFilters,
    notes,
    candidates,
    totalItems,
    selectedTab
  } = useAssignment()

  useAssignmentNotes()

  const { trackAssignmentView } = useTelemetry()
  const [editLinkedIn, setEditLinkedIn] = useState(false)

  const [showLinkSuccess, setShowLinkSuccess] = useState(false)

  const { getAiTemplates } = useAssignmentTemplates()

  useEffect(() => {
    getAiTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    trackAssignmentView({ assignmentId })
  }, [trackAssignmentView, assignmentId])

  const handleAssignmentUpdate = useCallback(
    (assignment: Assignment) => {
      setAssignmentDetails(assignment)
    },
    [setAssignmentDetails]
  )

  const handlePageChange = useCallback(
    (page: any) => {
      scrollToTop()
      onPageChange(page)
      const params = getAppliedFiltersParams(appliedFilters)
      params.append("page", page)
      getAssignmentCandidates(assignmentId, selectedStage, params)
    },
    [
      onPageChange,
      appliedFilters,
      assignmentId,
      getAssignmentCandidates,
      selectedStage
    ]
  )

  if (!assignmentDetails) {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title>{messages.seo.title.format(assignmentDetails.name)}</title>
      </Helmet>
      {assignmentDetails.companyId &&
        !showLinkSuccess &&
        !assignmentDetails.isCompanyLinkedToDataPool &&
        !editLinkedIn && (
          <LinkContainerStyled>
            <TGFlex>
              <Div>{messages.assignment.addLinkedIn}</Div>
              <Button
                ml="s"
                mode="primary"
                size="extra-small"
                onClick={() => setEditLinkedIn(true)}
              >
                {messages.person.details.linkGraph}
              </Button>
            </TGFlex>
          </LinkContainerStyled>
        )}
      <AssignmentDetailSection
        {...{
          editLinkedIn,
          setEditLinkedIn,
          showLinkSuccess,
          setShowLinkSuccess
        }}
      />
      <Div data-e2e-target={e2eTargets.assignmentDetails}>
        <Tabs>
          <Tab title="Assignment details">
            <AssignmentData
              onAssignmentUpdated={handleAssignmentUpdate}
              {...assignmentDetails}
            />
          </Tab>

          <Tab title="Target Companies">
            <AssignmentTargetCompanies />
          </Tab>

          <Tab
            title={`Candidates (${candidates ? totalItems : 0})`}
            defaultSelected
          >
            <CandidatesList handlePageChange={handlePageChange} />
          </Tab>

          <Tab title={`Notes (${notes.length})`}>
            <AssignmentNotes />
          </Tab>
          <Tab title="Tags">
            <AssignmentTags
              onAssignmentUpdated={handleAssignmentUpdate}
              {...assignmentDetails}
            />
          </Tab>
          <Tab title="Client Portal">
            <ClientPortalConfigurationWrapper />
          </Tab>
          <Tab title={messages.project.projectTemplatesAI}>
            <AssignmentTemplates />
          </Tab>
        </Tabs>
        <Flex>
          {selectedCandidatedId &&
            (selectedTab === "candidate" ? (
              <AssignmentPersonOverlay handlePageChange={handlePageChange} />
            ) : (
              <AssignmentCompanyPersonOverlay />
            ))}
        </Flex>
      </Div>
    </>
  )
}
