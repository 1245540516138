import React, { useCallback } from "react"
import { Note, PlusIcon, colors } from "@ikiru/talentis-fpc"
import { formatDateTime } from "utils/format-date"
import { Note as NoteType } from "components/NoteItem/types"
import { messages } from "setup/messages/messages"
import { generateNoteDate } from "components/NoteItem/helpers"
import { NoteButton, NoteFlexContainer } from "./styles"
import { useModal } from "utils/hooks/use-modal"
import AttachmentModel from "./NoteModal/AttachmentModel"
import { ModalNames } from "setup/modal/modal.definitions"

type NoteListItemProps = {
  note: NoteType
  onEdit: (note?: NoteType) => void
  onCreate?: VoidFunction
}

const NoteListItem = ({ note, onEdit, onCreate }: NoteListItemProps) => {
  const { open } = useModal()
  const {
    noteTitle,
    noteDescription,
    createdDate,
    updatedDate,
    createdBy,
    updatedBy
  } = generateNoteDate(note)

  const seeDocument = useCallback(
    (documentId: string) => {
      open(
        ModalNames.EmailAttachments,
        <AttachmentModel {...{ noteId: note.id, documentId }} />
      )
    },
    [note.id, open]
  )

  return (
    <NoteFlexContainer
      bg="grey.lightest"
      width="100%"
      justifyContent="space-between"
      pt="9px"
      pl="xs"
      pr="xs"
      pb="xxs"
    >
      <Note
        title={noteTitle}
        description={noteDescription}
        createdDate={createdDate}
        createdBy={createdBy}
        formattedCreatedDate={formatDateTime(createdDate)}
        formattedUpdatedDate={updatedDate ? formatDateTime(updatedDate) : null}
        updatedBy={updatedBy}
        updatedDate={updatedDate}
        moreButtonLabel={messages.person.note.showFullNote}
        lessButtonLabel={messages.person.note.showLessNote}
        linesNumber={noteTitle ? 1 : 2}
        useArrow={true}
        documents={note.documents}
        onDocumentClick={seeDocument}
        isHeaderFlexRow
        editModalButton
        isJustifyContent
        openModal={() => onEdit(note)}
      />
      {onCreate && (
        <NoteButton
          mode="standard-green"
          size="action-medium"
          onClick={onCreate}
          mr="xs"
        >
          <PlusIcon fill={colors.white.standard} />
        </NoteButton>
      )}
    </NoteFlexContainer>
  )
}

export default NoteListItem
