import React, { useCallback, useEffect, useReducer } from "react"
import { InternalSearchContext } from "./context"
import { internalSearchReducer, initialInternalSearchState } from "./reducer"
import {
  InternalSearchActionTypes,
  UpdateFilters,
  SetIsLoadingSearchResults,
  SetSections,
  Section,
  InternalFilterType,
  SetInternalPersons,
  SetInternalSearchPage,
  InternalSearchPageParams,
  ResetFilters,
  SetIsResetFilters,
  SetIsLookup,
  SetLookupSearchString
} from "./types"
import { getLocalSearchSection } from "../actions"
import get from "lodash/get"

type InternalSearchModuleProps = {
  children: React.ReactNode
}

export const InternalSearchModule = (props: InternalSearchModuleProps) => {
  const { children } = props
  const [state, dispatch] = useReducer(
    internalSearchReducer,
    initialInternalSearchState
  )

  const setIsLoadingSearchResults: SetIsLoadingSearchResults = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetIsLoadingSearchResults,
        payload: data
      })
    },
    [dispatch]
  )

  const setSections: SetSections = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetSections,
        payload: data
      })
    },
    [dispatch]
  )

  const setInternalPersons: SetInternalPersons = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetInternalPersons,
        payload: data
      })
    },
    [dispatch]
  )

  const setInternalSearchPage: SetInternalSearchPage = (
    page: InternalSearchPageParams
  ) => {
    dispatch({
      type: InternalSearchActionTypes.SetInternalSearchPage,
      payload: page
    })
  }

  const updateFilters: UpdateFilters = useCallback(
    (data) => {
      dispatch({ type: InternalSearchActionTypes.UpdateFilters, payload: data })
    },
    [dispatch]
  )

  const clearFilters: UpdateFilters = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.ClearCertainFilters,
        payload: data
      })
    },
    [dispatch]
  )

  const setIsLookup: SetIsLookup = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetIsLookup,
        payload: data
      })
    },
    [dispatch]
  )

  const setLookupSearchString: SetLookupSearchString = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetLookupSearchString,
        payload: data
      })
    },
    [dispatch]
  )

  const addArrayBasedInternalFilter = useCallback(
    (filterName: InternalFilterType | string, value: string | string[]) => {
      const values = !Array.isArray(value) ? [value] : value

      const trimmedValues = values.map((v) => v.trim()).filter((v) => v !== "")

      const currentValue = get(state.filters, filterName, []) as string[]

      let newValue: string[] = [...currentValue]
      trimmedValues.forEach((v) => {
        if (!currentValue.includes(v)) {
          newValue = [...newValue, v]
        }
      })

      updateFilters({
        [filterName]: newValue
      })
    },

    [state.filters, updateFilters]
  )

  const removeArrayBasedInternalFilter = useCallback(
    (filterName: InternalFilterType | string, value: string) => {
      const currentValue = get(state.filters, filterName, []) as string[]
      updateFilters({
        [filterName]: currentValue.filter((v: string) => v !== value)
      })
    },
    [state.filters, updateFilters]
  )

  const clearArrayBasedFilter = useCallback(
    (filterName: InternalFilterType | string) => {
      const currentValue = get(state.filters, filterName, []) as string[]

      clearFilters({
        [filterName]: currentValue
      })
    },
    [state.filters, clearFilters]
  )

  const resetFilters: ResetFilters = useCallback(() => {
    dispatch({
      type: InternalSearchActionTypes.ResetFilters
    })
  }, [dispatch])

  const setIsResetFilters: SetIsResetFilters = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetIsResetFilters,
        payload: data
      })
    },
    [dispatch]
  )

  useEffect(() => {
    let isSectionsLoading = true

    const fetchSection = async () => {
      try {
        const sections = await getLocalSearchSection()
        if (isSectionsLoading) {
          const optionsSearch = sections.map((section: Section) => ({
            value: section.id,
            label: section.name
          }))
          setSections(optionsSearch)
        }
      } catch (error) {
        console.error("Error fetching sections:", error)
      }
    }

    fetchSection()

    return () => {
      isSectionsLoading = false
    }
  }, [setSections])

  return (
    <InternalSearchContext.Provider
      value={{
        ...state,
        updateFilters,
        setIsLoadingSearchResults,
        setSections,
        addArrayBasedInternalFilter,
        removeArrayBasedInternalFilter,
        clearArrayBasedFilter,
        clearFilters,
        setInternalPersons,
        setInternalSearchPage,
        resetFilters,
        setIsResetFilters,
        setIsLookup,
        setLookupSearchString
      }}
    >
      {children}
    </InternalSearchContext.Provider>
  )
}
