import React from "react"
import { Flex } from "@ikiru/talentis-fpc"

const FilterWrapper: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => (
  <Flex
    height="auto"
    p="xs"
    width="100%"
    mb="xxs"
    bg="green.lightest"
    justifyContent="center"
    position="relative"
  >
    {children}
  </Flex>
)

export default React.memo(FilterWrapper)
