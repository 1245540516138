import React from "react"
import { StyledFlex } from "./style"
import { SearchSection } from "../SearchSection"
import { Form, Formik } from "formik"
import { initialValues } from "views/internal-search/definitions"
import { noop } from "lodash"

const InternalSearchCriteria = () => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={noop}
      validateOnBlur={false}
    >
      <Form>
        <StyledFlex>
          <SearchSection />
        </StyledFlex>
      </Form>
    </Formik>
  )
}

export default InternalSearchCriteria
