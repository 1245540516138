import styled from "styled-components"

type SidebarProps = {
  isMouseOver?: boolean
}

export const Sidebar = styled.div<SidebarProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ isMouseOver }) => (isMouseOver ? 170 : 60)}px;
  z-index: 13;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white.standard};
  border-right: 3px solid;
  border-color: ${({ theme }) => theme.colors.grey.light};
  transition: 0.4s;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  box-shadow: ${({ isMouseOver }) =>
    isMouseOver ? "3px 0px 7px rgba(213, 222, 231, 0.5)" : "none"};
  @media only screen and (min-height: 394px) and (max-height: 695px) {
    width: ${({ isMouseOver }) => (isMouseOver ? 170 : 50)}px;
  }
`

export const SidebarMainContainer = styled.div`
  padding-left: ${({ theme }) => theme.space.xs}px;
  padding-right: ${({ theme }) => theme.space.xxs}px;
  padding-top: ${({ theme }) => theme.space.xs}px;
  padding-bottom: ${({ theme }) => theme.space.xs}px;
  height: 100%;
`

export const SidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  @media only screen and (min-height: 394px) and (max-height: 565px) {
    justify-content: flex-start;
    text-align: center;
    align-items: center;
  }
`
