import React from "react"
import { Div } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { PersonalNoteItemStyled, PersonalNotesWrapper } from "./styles"
import { ShowMore } from "components/ShowMore"
import { Note } from "components/Notes/types"
import { PersonNote } from "./PersonNote"

type PersonFooterProps = {
  bio: string
  note?: Note
}

export const PersonFooter = ({ bio, note }: PersonFooterProps) => {
  return (
    <PersonalNotesWrapper>
      <PersonalNoteItemStyled ml="5px">
        <Div mr="10px">
          <ShowMore
            isSmall
            title={messages.person.bio.title}
            visibleLines={3}
            description={bio}
          />
        </Div>
      </PersonalNoteItemStyled>
      {note && <PersonNote note={note} />}
    </PersonalNotesWrapper>
  )
}
