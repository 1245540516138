import React, { useMemo, KeyboardEvent, FocusEvent } from "react"
import { Div } from "@ikiru/talentis-fpc"
import { sectionFieldSearchValuesTemplateString } from "./consts"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { get } from "lodash"
import { PreferredList } from "../PreferredList"
import { useArrayFieldInputChange } from "views/internal-search/hooks"

type InputFieldProps = {
  index: number
  indexSection: number
}

export const InputField = ({ index, indexSection }: InputFieldProps) => {
  const { updateFilters, filters, removeArrayBasedInternalFilter } =
    useInternalSearch()

  const fieldSearchValuesName = sectionFieldSearchValuesTemplateString.format(
    indexSection,
    index
  )

  const handleInputChange = (e: React.FocusEvent<HTMLInputElement>) => {
    updateFilters({
      [fieldSearchValuesName]: [e.currentTarget.value]
    })
  }

  const isValues = useMemo(() => {
    const condition =
      //@ts-ignore
      filters?.sections?.[indexSection]?.fields?.[index]?.condition
    return condition === "contain"
  }, [filters, indexSection, index])

  const currentFieldSearchValues = get(filters, fieldSearchValuesName, [])

  const onKeyPress = useArrayFieldInputChange({
    field: fieldSearchValuesName
  })

  const onBlur = useArrayFieldInputChange({
    field: fieldSearchValuesName,
    isOnBlur: true
  })

  const onPressInput = (
    e: KeyboardEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>
  ) => {
    if ((e as KeyboardEvent<HTMLInputElement>).key === "Enter") {
      e.preventDefault()
    }
  }

  return (
    <>
      {isValues ? (
        <>
          <Div width="250px">
            <FormikInput
              name={fieldSearchValuesName}
              label="Search text"
              variant="middle"
              disableDefaultOnBlur
              placeholder="Search text"
              onBlur={onBlur as (e: React.FocusEvent<HTMLInputElement>) => void}
              onKeyPress={
                onKeyPress as (e: React.KeyboardEvent<HTMLInputElement>) => void
              }
            />
            <PreferredList
              keywords={currentFieldSearchValues}
              onKeywordRemove={(value) =>
                removeArrayBasedInternalFilter(fieldSearchValuesName, value)
              }
            />
          </Div>
        </>
      ) : (
        <Div width="250px">
          <FormikInput
            name={fieldSearchValuesName}
            label="Search text"
            variant="middle"
            disableDefaultOnBlur
            placeholder="Search text"
            onChange={(e: React.FocusEvent<HTMLInputElement>) =>
              handleInputChange(e)
            }
            defaultValue={currentFieldSearchValues[0]}
            onKeyPress={
              onPressInput as (e: React.KeyboardEvent<HTMLInputElement>) => void
            }
          />
        </Div>
      )}
    </>
  )
}
