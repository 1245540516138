import React from "react"
import { colors, Flex, fontFamilies, SmallText } from "@ikiru/talentis-fpc"
import { StyledBadge } from "./styles"
import { messages } from "setup/messages/messages"

type PersonActivitiesProps = {
  assignmentCount: number
  campaignCount: number
}

export const PersonActivities = ({
  assignmentCount,
  campaignCount
}: PersonActivitiesProps) => {
  const activitiesDetails = [
    {
      name: messages.talentGraphSearch.candidateDetails.assignment,
      count: assignmentCount
    },
    {
      name: messages.talentGraphSearch.candidateDetails.campaign,
      count: campaignCount
    }
  ]

  return (
    <Flex flexDirection="column" mt="xxs" ml="xxs">
      {activitiesDetails.map(({ name, count }) => (
        <Flex pr="s" alignItems="center" lineHeight={0} mb="xxs">
          <SmallText
            mb="none"
            color={colors.green.dark}
            fontFamily={fontFamilies.gibson}
            fontWeight={500}
          >
            {name}
          </SmallText>
          <StyledBadge count={count}>{count}</StyledBadge>
        </Flex>
      ))}
    </Flex>
  )
}
