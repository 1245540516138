import {
  dates,
  docs,
  numbers,
  predefined,
  strings
} from "views/internal-search/InternalSearchModule/consts"

export const defaultConditionValue = (selectedFieldType: string) => {
  switch (selectedFieldType) {
    case "string":
      return "beginWith"
    case "referenced":
    case "enum":
      return "any"
    case "date":
      return "between"
    case "money":
    case "number":
      return "equal"
    case "document":
      return "contain"
    default:
      return ""
  }
}

export const conditionOptions = (selectedFieldType: string) => {
  switch (selectedFieldType) {
    case "string":
      return strings
    case "referenced":
    case "enum":
      return predefined
    case "date":
      return dates
    case "number":
    case "money":
      return numbers
    case "document":
      return docs
    default:
      return []
  }
}
