import React from "react"
import { messages } from "setup/messages/messages"
import { Button, Flex, H2, H4, Link, SmallText } from "@ikiru/talentis-fpc"
import InternalSearchCriteria from "./components/InternalSearchCriteria"
import { Tabs } from "views/companies/components/company-details/components/Tabs/Tabs"
import Tab from "views/companies/components/company-details/components/Tabs/Tab"
import InternalSearchResults from "./components/InternalSearchResults"
import { useInternalSearch } from "./InternalSearchModule/context"
import { ContactLookup } from "./components/ContactLookup/ContactLookup"

export const InternalSearchPage = () => {
  const { isLoadingSearchResults, setIsResetFilters, isResetFilters } =
    useInternalSearch()
  return (
    <Flex flexDirection="column" width="100%">
      <H2 color="#009BA6">Internal search</H2>
      <ContactLookup />
      <Flex
        justifyContent="space-between"
        backgroundColor="#224160"
        p="xs"
        mt="xs"
      >
        <H4 m="0" color="#FFFFFF">
          {messages.internalSearch.buildYourSearch}
        </H4>

        <Flex alignItems="center">
          <Link onClick={() => setIsResetFilters(true)} my="none" mr="15px">
            <SmallText fontWeight="600" color="white.standard" my="none">
              {messages.internalSearch.startNewSearch}
            </SmallText>
          </Link>
          <Button mode="standard-grey-light" size="small" mr="s">
            {messages.internalSearch.loadSavedSearch}
          </Button>
          <Button mode="standard-grey-light" size="small">
            {messages.internalSearch.saveSearch}
          </Button>
        </Flex>
      </Flex>

      <Tabs>
        <Tab title="Search criteria" isActive={isResetFilters}>
          <InternalSearchCriteria />
        </Tab>

        <Tab title="Results" isActive={isLoadingSearchResults}>
          <InternalSearchResults />
        </Tab>
      </Tabs>
    </Flex>
  )
}
