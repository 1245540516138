import React from "react"
import { Formik, Form } from "formik"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { messages } from "setup/messages/messages"
import { Button, Div, Flex, colors } from "@ikiru/talentis-fpc"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import {
  contactLookupFormInitialValues,
  ContactLookupFormValues,
  contactLookupInputName
} from "./definitions"
import { getLocalContactLookupResult } from "views/internal-search/actions"

export const ContactLookup = () => {
  const {
    setInternalPersons,
    setIsLoadingSearchResults,
    setInternalSearchPage,
    setIsLookup,
    setLookupSearchString
  } = useInternalSearch()

  const onSubmit = async (values: ContactLookupFormValues) => {
    setIsLoadingSearchResults(true)
    let searchResult = await getLocalContactLookupResult(values)
    setIsLookup(true)
    setLookupSearchString(values)
    setInternalSearchPage({
      hasNextPage: searchResult.hasNextPage,
      hasPreviousPage: searchResult.hasPreviousPage,
      pageCount: searchResult.pageCount,
      pageNumber: searchResult.pageNumber,
      pageSize: searchResult.pageSize,
      totalItemCount: searchResult.totalItemCount,
      maxPages: 9
    })
    setInternalPersons(searchResult.people)
  }

  return (
    <Formik initialValues={contactLookupFormInitialValues} onSubmit={onSubmit}>
      {({ values }) => {
        return (
          <Form>
            <Flex
              p="xs"
              bg={colors.grey.light}
              flexWrap="wrap"
              alignItems="center"
            >
              <Div minWidth={["100%", "400px"]} mr="s">
                <FormikInput
                  variant="middle"
                  name={contactLookupInputName}
                  id={contactLookupInputName}
                  label={messages.form.generic.lookUpPersonName}
                />
              </Div>

              <Button
                mt={["xs", "none"]}
                type="submit"
                mode="standard-green"
                size="small"
                disabled={!values.quickSearchValue}
              >
                {messages.form.generic.contactLookup}
              </Button>
            </Flex>
          </Form>
        )
      }}
    </Formik>
  )
}
