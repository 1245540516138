import React, { useMemo, useState } from "react"
import { Button, Div, Flex, SmallText } from "@ikiru/talentis-fpc"
import { PhoneDataType, TaggedEmail } from "views/persons/person.types"

type PersonContactsProps = {
  taggedEmails?: TaggedEmail[]
  phones?: PhoneDataType[]
}

export const PersonContacts = ({
  taggedEmails = [],
  phones = []
}: PersonContactsProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const isShowedButton = useMemo(
    () =>
      taggedEmails.length > 2 ||
      phones.length > 2 ||
      taggedEmails.length + phones.length > 3,
    [taggedEmails, phones]
  )

  const showedTaggedEmails = useMemo(
    () => (isExpanded ? [...taggedEmails] : taggedEmails.slice(0, 1)),
    [isExpanded, taggedEmails]
  )

  const showedPhones = useMemo(
    () => (isExpanded ? [...phones] : phones.slice(0, 1)),
    [isExpanded, phones]
  )

  return (
    <Flex flexDirection="column" mt="xxs" ml="xxs">
      {showedTaggedEmails.map((emailObj, index) => (
        <SmallText key={index}>{emailObj.email}</SmallText>
      ))}

      {showedPhones.map((phoneObj, index) => (
        <SmallText key={index}>{phoneObj.phoneNumber}</SmallText>
      ))}
      {isShowedButton && (
        <Div pt="xxs">
          <Button
            mode="standard-grey-light"
            size="extra-small"
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            {isExpanded ? "Hide all contacts" : "Show all contacts"}
          </Button>
        </Div>
      )}
    </Flex>
  )
}
